<template>
  <div class="page-main">
    <!--主体-->
    <div class="page-body" v-loading="recordLoading">
      <el-row class="subject">
        <div>设备详情</div>
      </el-row>
      <el-row class="prop-row" :gutter="20">
        <el-col :span="12">
          <div><span class="prop">组织名称：</span>{{record.orgName}}</div>
        </el-col>
        <el-col :span="12">
          <div><span class="prop">组织税号：</span>{{record.orgTaxNo}}</div>
        </el-col>
      </el-row>
      <el-row class="prop-row" :gutter="20">
        <el-col v-if="record.billingChannel !== 'QD-RPA'" :span="12">
          <div><span class="prop">设备名称：</span>{{record.alias}}</div>
        </el-col>
        <el-col :span="12">
          <div><span class="prop">开票方式：</span>{{fmtDeviceManufacturer(record.billingChannel)}}</div>
        </el-col>
      </el-row>

      <div v-if="record.billingChannel === 'QD-RPA'">
        <el-row  class="prop-row" :gutter="20">
          <el-col :span="12">
            <div><span class="prop">电子税局登录账户：</span>{{record.elecAccount}}</div>
          </el-col>
          <el-col :span="12">
            <div><span class="prop">电子税局登录密码：</span>{{record.elecPassword}}</div>
          </el-col>
        </el-row>

        <el-row  class="prop-row" :gutter="20">
          <el-col :span="12">
            <div><span class="prop">开票人电话：</span>{{record.openPhone}}</div>
          </el-col>
          <el-col :span="12">
            <div><span class="prop">开票所在地编码：</span>{{record.openSiteCode}}</div>
          </el-col>
        </el-row>

        <el-row  class="prop-row" :gutter="20">
          <el-col :span="12">
            <div><span class="prop">开通状态：</span>{{record.openState===1?'已开通':'未开通'}}</div>
          </el-col>
          <el-col :span="12">
            <div><span class="prop">授权状态：</span>{{record.authState===1?'已授权':'未授权'}}</div>
          </el-col>
        </el-row>

        <el-row  class="prop-row" :gutter="20">
          <el-col :span="12">
            <div><span class="prop">认证有效期：</span>{{record.authValidity}}</div>
          </el-col>
          <el-col :span="12">
            <div><span class="prop">认证更新时间：</span>{{record.authUpdated}}</div>
          </el-col>
        </el-row>
      </div>

      <el-row v-if="record.billingChannel !== 'QD-RPA'" class="prop-row" :gutter="20">
        <el-col :span="12">
          <div><span class="prop">分机编号：</span>{{record.extensionNo}}</div>
        </el-col>
        <el-col :span="12">
          <div><span class="prop">设备密码：</span>{{record.password}}</div>
        </el-col>
      </el-row>

      <el-row v-if="record.billingChannel !== 'QD-RPA'" class="prop-row" :gutter="20">
        <el-col :span="12">
          <div><span class="prop">终端标识：</span>{{record.identifier}}</div>
        </el-col>
      </el-row>

      <el-row v-if="record.billingChannel !== 'QD-RPA'" class="prop-row" :gutter="20">
        <el-col :span="12">
          <div><span class="prop">助手账号：</span>{{record.agentUsername}}</div>
        </el-col>
        <el-col :span="12">
          <div><span class="prop">助手密码：</span>{{record.agentPassword}}</div>
        </el-col>
      </el-row>
      <el-row v-if="record.billingChannel !== 'QD-RPA'" class="prop-row" :gutter="20">
        <el-col :span="12">
          <div><span class="prop">税盘编号：</span>{{record.identifier}}</div>
        </el-col>
        <el-col :span="12">
          <div><span class="prop">开票默认：</span>{{record.defaulted==null?'-':record.defaulted?'是':'否'}}</div>
        </el-col>
      </el-row>
      <el-row v-if="record.billingChannel !== 'QD-RPA'">
        <div class="split-line"></div>
      </el-row>
      <el-row v-if="record.billingChannel !== 'QD-RPA'" class="prop-row" :gutter="20">
        <el-col :span="12">
          <div><span class="prop">电子普票限额：</span>{{record.electronicOrdinaryInvoiceLimit}}元</div>
        </el-col>
        <el-col :span="12">
          <div><span class="prop">纸质普票限额：</span>{{record.paperOrdinaryInvoiceLimit}}元</div>
        </el-col>
      </el-row>
      <el-row  v-if="record.billingChannel !== 'QD-RPA'" class="prop-row" :gutter="20">
        <el-col :span="12">
          <div><span class="prop">纸质专票限额：</span>{{record.paperSpecialInvoiceLimit}}元</div>
        </el-col>
      </el-row>
      <el-row v-if="record.billingChannel !== 'QD-RPA'">
        <div class="split-line"></div>
      </el-row>
      <el-row v-if="record.billingChannel !== 'QD-RPA'">
        <div class="category">在线状态</div>
      </el-row>
      <el-row v-if="record.billingChannel !== 'QD-RPA'" class="prop-row prop-tbl prop-tbl-a">
        <el-col :span="8">
          <div>助手信息</div>
        </el-col>
        <el-col :span="8">
          <div>{{record.orgTaxNo==''?'--':record.orgTaxNo}}</div>
        </el-col>
        <el-col :span="8">
          <div>
            <AgentOnlineStatus :status="record.agentStatus"></AgentOnlineStatus>
          </div>
        </el-col>
      </el-row>
      <el-row v-if="record.billingChannel !== 'QD-RPA'" class="prop-row prop-tbl prop-tbl-b">
        <el-col :span="8">
          <div>设备信息</div>
        </el-col>
        <el-col :span="8">
          <div>{{record.agentOrgTaxNo==''?'--':record.agentOrgTaxNo}}</div>
        </el-col>
        <el-col :span="8">
          <div>
            <DeviceOnlineStatus :status="record.deviceStatus"></DeviceOnlineStatus>
          </div>
        </el-col>
      </el-row>
      <el-row v-if="record.billingChannel !== 'QD-RPA'" class="prop-row prop-tbl prop-tbl-a">
        <el-col :span="8">
          <div>助手与设备匹配状态</div>
        </el-col>
        <el-col :span="8">
          <div>--</div>
        </el-col>
        <el-col :span="8">
          <div>
            <DeviceMatchStatus :status="record.matchStatus"></DeviceMatchStatus>
          </div>
        </el-col>
      </el-row>

      <el-row v-if="record.billingChannel !== 'QD-RPA'" class="prop-row prop-tbl prop-tbl-a">
        <el-col :span="8">
          <div>助手与UKey匹配状态</div>
        </el-col>
        <el-col :span="8">
          <div>--</div>
        </el-col>
        <el-col :span="8">
          <div>
            <DeviceMatchStatus :status="record.matchStatus"></DeviceMatchStatus>
          </div>
        </el-col>
      </el-row>
      <el-row v-if="record.billingChannel !== 'QD-RPA'">
        <div class="split-line"></div>
      </el-row>
      <el-row v-if="record.billingChannel !== 'QD-RPA'">
        <div class="category">负责人信息</div>
      </el-row>
      <el-row v-if="record.billingChannel !== 'QD-RPA'" class="prop-row" :gutter="20">
        <el-col :span="12">
          <div><span class="prop">税控设备负责人：</span>{{record.contactPersons}}</div>
        </el-col>
        <el-col :span="12">
          <div><span class="prop">负责人联系方式：</span>{{record.contactChannel}}</div>
        </el-col>
      </el-row>
      <el-row v-if="record.billingChannel !== 'QD-RPA'" class="prop-row" :gutter="20">
        <el-col :span="24">
          <div><span class="prop">税控设备所在地：</span>{{record.location}}</div>
        </el-col>
      </el-row>
    </div>
    <!--底部-->
    <div class="page-foot">
      <el-button class="el-button-ext"
                 @click="handleCancel">关闭
      </el-button>
      <el-button v-role="'RS-M04-F02'"
                 class="el-button-ext"
                 :disabled="record.id==0"
                 @click="handleUpdate"
                 type="primary">修改
      </el-button>
    </div>
  </div>
</template>

<script>
import AgentOnlineStatus from '../../components/widgets/AgentOnlineStatus'
import DeviceOnlineStatus from '../../components/widgets/DeviceOnlineStatus'
import DeviceMatchStatus from '../../components/widgets/DeviceMatchStatus'
import { FmtEnum } from '../../util/enumUtil'

const DEFT_RECORD = {
  id: 0,
  orgId: 0,
  orgName: '',
  orgTaxNo: '',
  extensionNo: '',
  alias: '',
  agentStatus: 0,
  deviceStatus: 0,
  matchStatus: 0,
  password: '',
  disabled: false,
  identifier: '',
  contactPersons: '',
  contactChannel: '',
  location: '',
  agentUsername: '',
  agentPassword: '',
  agentOrgTaxNo: '',
  agentExtensionNo: '',
  agentManufacturer: '',
  defaulted: null,
  autoPull: null,
  bank: '',
  account: '',
  payee: '',
  auditor: '',
  payer: '',
  electronicOrdinaryInvoiceLimit: '',
  paperSpecialInvoiceLimit: '',
  paperOrdinaryInvoiceLimit: '',
}
export default {
  name: 'EquipmentDetail',
  components: {
    AgentOnlineStatus,
    DeviceOnlineStatus,
    DeviceMatchStatus
  },
  props: {
    record: {
      type: Object,
      required: true,
      default: () => {
        return DEFT_RECORD
      }
    },
    recordLoading: {
      type: Boolean,
      default: false
    },
    cancel: {
      type: Function,
      default: () => {
        return function () {
        }
      }
    },
    update: {
      type: Function,
      default: () => {
        return function () {
        }
      }
    }
  },
  data(){
    return {
      billingChannels:[]  // 开票方式列表
    }
  },
  created () {
    this.getDicts('equ_billing_mode').then(({ data }) => this.billingChannels = data)
  },
  methods: {
    handleCancel () {
      this.$emit('cancel', this.record.id)
    },
    handleUpdate () {
      this.$emit('update', this.record.id)
    },
    fmtDeviceManufacturer (value) {
      return this.getDictLabel(this.billingChannels, value)
    }
  }
}
</script>

<style scoped lang="scss">
  .page-main {

    .page-body {
      font-size: 14px;
      color: #333333;
      position: absolute;
      top: 24px;
      right: 0px;
      padding-right: 24px;
      bottom: 100px;
      left: 24px;
      overflow-y: scroll;
      overflow-x: hidden;

      .subject {
        font-size: 18px;
        font-weight: 600;
        line-height: 24px;
        padding-bottom: 24px;
      }

      .prop-row {
        line-height: 36px;

        span.prop {
          color: #666666;
        }
      }

      .prop-tbl {
        text-align: center;
      }

      .prop-tbl-a {
        background-color: #EEEEEE;
      }

      .prop-tbl-b {
        background-color: #FFFFFF;
      }

      .prop-tbl .el-col {
        border-left: 1px solid #D8D8D8;
      }

      .prop-tbl .el-col:first-child {
        border-left: none;
      }

      .split-line {
        height: 1px;
        background-color: #CCCCCC;
        margin: 24px 0px;
      }

      .category {
        font-size: 14px;
        font-weight: 600;
        line-height: 20px;
        padding: 8px 0px;
      }
    }

    .page-foot {
      position: fixed;
      bottom: 34px;
      left: 24px;
      right: 24px;
      text-align: right;
    }
  }
</style>
