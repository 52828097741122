<template>
  <el-form ref="editForm" :model="record" :rules="ruless" v-loading="recordLoading" label-position="top">
    <div class="edit-wrap">
      <div class="org-item">
        <el-form-item prop="orgId" label="组织名称">
          <organization-select :model="record" :is-add-all="false"
                               @handleSelectAction="generateAgentUsername"></organization-select>
        </el-form-item>
        <el-form-item label="组织税号">
          <el-input v-model="orgTaxpayer.taxNo" disabled></el-input>
        </el-form-item>
        <el-form-item label="设备类型">
          <el-select v-model="record.equipmentType" @change="changeNoYes">
            <el-option v-for="b in equipmentTypes"
                       :key="b.value"
                       :label="b.label"
                       :value="b.value"></el-option>
          </el-select>
        </el-form-item>
      </div>
      <div class="form-item">
        <el-form-item prop="billingChannel" label="开票方式" v-show="record.equipmentType != 'PRINT'">
          <el-select v-model="record.billingChannel">
            <el-option v-for="b in billingChannels"
                       :key="b.value"
                       :label="b.label"
                       :value="b.value"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop="openStatus" label="开通状态" v-show="record.equipmentType != 'PRINT'">
          <el-select v-model="record.openStatus" :disabled="record.billingChannel !== 'QD-RPA'">
            <el-option label="未开通" value="0"/>
            <el-option label="已开通" value="1"/>
          </el-select>
        </el-form-item>
        <el-form-item v-show="record.billingChannel == 'QD-RPA'" prop="openSiteCode" label="开票点编码">
          <el-input v-model="record.openSiteCode"/>
        </el-form-item>
        <el-form-item v-show="record.billingChannel == 'QD-RPA'" prop="elecAccount" label="电子税局登录账号">
          <el-input v-model="record.elecAccount"/>
        </el-form-item>
        <el-form-item v-show="record.billingChannel == 'QD-RPA'" prop="elecPassword" label="电子税局登录密码">
          <el-input type="password"  show-password v-model="record.elecPassword"/>
        </el-form-item>
        <el-form-item v-show="isRpa" prop="extensionNo" label="分机编号">
          <span slot="label">
            <span>
              <span>分机编号</span>
              <el-tooltip placement="top" :content="this.tips">
                <i class="el-icon-question icon"></i>
              </el-tooltip>
            </span>
          </span>
          <el-input v-model="record.extensionNo" @blur="generateAgentUsername"/>
        </el-form-item>
        <el-form-item v-show="isRpa && record.equipmentType != 'PRINT'"  prop="password" label="设备密码">
          <el-input v-model="record.password"/>
        </el-form-item>
        <!-- 根据设备切换名称 -->
        <el-form-item v-show="isRpa && record.equipmentType != 'PRINT'" prop="alias" label="税盘名称">
          <el-input v-model="record.alias"/>
        </el-form-item>
        <el-form-item v-show="isRpa" prop="agentUsername" label="助手账号">
          <el-input v-model="record.agentUsername" disabled/>
        </el-form-item>
        <el-form-item v-show="isRpa" prop="agentPassword" label="助手密码">
          <el-input v-model="record.agentPassword"/>
        </el-form-item>
        <el-form-item prop="taxPlateNo" v-show="record.billingChannel == 'HX-FWQ-JSP'" label="金税盘编号">
          <el-input v-model="record.taxPlateNo"></el-input>
        </el-form-item>
        <el-form-item v-show="isRpa && record.equipmentType != 'PRINT'" prop="identifier" label="税盘编号">
          <el-input v-model="record.identifier"/>
        </el-form-item>
        <el-form-item v-show="record.billingChannel == 'LS-ZJ-UKEY'" prop="province" label="所属省份">
          <el-select v-model="record.province">
            <el-option v-for="b in provinces"
                       :key="b.value"
                       :label="b.label"
                       :value="b.label"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item v-show="record.billingChannel == 'LS-ZJ-UKEY'" prop="registrationCode" label="UKey注册码">
          <textarea v-model="record.registrationCode"></textarea>
        </el-form-item>
        <el-form-item prop="printerBrands" label="打印机品牌" v-show="record.equipmentType == 'PRINT'">
          <el-select v-model="record.printerBrand" placeholder="请选择">
            <el-option v-for="item in printerBrands" :key="item.value" :label="item.label" :value="item.value"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop="connectionType" label="连接类型" v-show="record.equipmentType == 'PRINT'">
          <el-select v-model="record.connectionType" placeholder="请选择">
            <el-option label="ip" value="0"></el-option>
            <el-option label="USB" value="1"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop="printerIp" label="打印机ip" v-show="record.equipmentType == 'PRINT'">
          <el-input v-model="record.printerIp"/>
        </el-form-item>
        <el-form-item prop="usbName" label="USB名称" v-show="record.equipmentType == 'PRINT'">
          <el-select v-model="record.usbName" placeholder="请选择">
            <el-option v-for="item in usbNames" :key="item.value" :label="item.label" :value="item.value"></el-option>
          </el-select>
        </el-form-item>
      </div>
      <hr/>
      <div class="form-item" v-show="record.equipmentType != 'PRINT'">
        <el-form-item v-show="isRpa" prop="payee" label="收款人">
          <el-input v-model="record.payee"></el-input>
        </el-form-item>
        <el-form-item v-show="isRpa" prop="auditor" label="复核人">
          <el-input v-model="record.auditor"></el-input>
        </el-form-item>
        <el-form-item prop="payer" label="开票人">
          <el-input v-model="record.payer"></el-input>
        </el-form-item>
        <el-form-item v-show="record.billingChannel === 'QD-RPA'" prop="openPhone" label="开票人手机号">
           <span slot="label">
            <span>
              <span>开票人手机号</span>
              <el-tooltip placement="top" content="此处填入的手机号需与电子税局端一致">
                <i class="el-icon-info"></i>
              </el-tooltip>
            </span>
          </span>
          <el-input v-model="record.openPhone"></el-input>
        </el-form-item>
        <el-form-item v-show="isRpa" prop="electronicOrdinaryInvoiceLimit" label="电子普票限额">
          <el-select v-model="record.electronicOrdinaryInvoiceLimit">
            <el-option
              v-for="item in invoiceLimits"
              :key="item"
              :label="item"
              :value="item">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item v-show="isRpa" prop="electronicOrdinaryInvoiceLimit" label="电子专票限额">
          <el-select v-model="record.electronicSpecialInvoiceLimit">
            <el-option
              v-for="item in invoiceLimits"
              :key="item"
              :label="item"
              :value="item">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item v-show="isRpa" prop="paperSpecialInvoiceLimit" label="纸质专票限额">
          <el-select v-model="record.paperSpecialInvoiceLimit">
            <el-option
              v-for="item in invoiceLimits"
              :key="item"
              :label="item"
              :value="item">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item v-show="isRpa" prop="paperOrdinaryInvoiceLimit" label="纸质普票限额">
          <el-select v-model="record.paperOrdinaryInvoiceLimit">
            <el-option
              v-for="item in invoiceLimits"
              :key="item"
              :label="item"
              :value="item">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop="defaulted" label="开票默认" style="display: flex;margin: 20px 3px;">
          <el-switch v-model="record.defaulted"
                     active-text="是"
                     inactive-text="否"
                     active-color="#13CE66"
                     inactive-color="#FF4949">
          </el-switch>
        </el-form-item>
      </div>
      <div v-show="isRpa && record.equipmentType != 'PRINT'">
        <hr/>
        <div class="form-item">
          <el-form-item prop="contactPersons" label="税控设备负责人">
            <el-input v-model="record.contactPersons"></el-input>
          </el-form-item>
          <el-form-item prop="contactChannel" label="负责人联系方式">
            <el-input v-model="record.contactChannel"></el-input>
          </el-form-item>
        </div>
        <div class="form-item">
          <el-form-item prop="location" label="税控设备所在地">
            <el-input v-model="record.location" style="width: 460px;margin-left:20px;"></el-input>
          </el-form-item>
        </div>
      </div>

      <div class="form-foot">
        <el-button @click="handleCancel">取消</el-button>
        <el-button @click="handleSave" :loading="loading" type="primary">保存</el-button>
      </div>
    </div>
  </el-form>
</template>

<script>
import RegexLib from '@/assets/js/regex-lib'
import {ReqEquipmentSaveEquipment} from '@/service/equipment'
import {selectOrganizationTaxpayer} from '@/service/organization'
import OrganizationSelect from '@/components/widgets/OrganizationSelect'

export default {
  name: 'EquipmentEdit',
  components: { OrganizationSelect },
  props: {
    record: {
      type: Object,
      default: () => {
      }
    },
    recordLoading: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    'record.orgId': {
      handler: 'generateAgentUsername',
      deep: true,
      immediate: true
    },
    'record.billingChannel':{
      handler: 'handlerBillChannel',
      deep: true,
      immediate: true
    }
  },
  computed: {
    ruless: function () {
      if(this.record.equipmentType == null){
        return this.rules
      }
      switch (this.record.equipmentType){
        case 'PRINT':
          return this.rules2
        case 'BILLING':
        default:
          return this.rules
      }
    }
  },
  data () {
    return {
      tips: '开票方式为"航信-服务器-金税盘接口"时此字段格式为："分机编号-终端号"',
      loading: false,
      orgTaxpayer: {},
      isRpa:false,
      rules: {
        orgId: [{ required: true, message: '请选择所属组织' }],
        extensionNo: [
          { required: true, message: '请输入分机编号' },
          { pattern: RegexLib.EquipmentExtensionNo, message: '只允许 1~20 位数字或字母', trigger: 'blur' }
        ],
        billingChannel: [{ required: true, message: '请选择开票方式' }],
        alias: [{ pattern: RegexLib.EquipmentAlias, message: '只允许 2~10 位非特殊字符', trigger: 'blur' }],
        password: [
          { required: true, message: '请输入设备密码' },
          { pattern: RegexLib.EquipmentPassword, message: '只允许 6~16 位数字或字母', trigger: 'blur' }
        ],
        agentUsername: [{ required: true, message: '请输入助手账号' }],
        agentPassword: [
          { required: true, message: '请输入助手密码' },
          { pattern: RegexLib.EquipmentAgentPassword, message: '只允许 8~20 位数字或字母', trigger: 'blur' }
        ],
        payee: [
          { required: true, message: '请输入收款人' },
          { pattern: RegexLib.EquipmentPeopleName, message: '只允许 1~10 位非特殊字符', trigger: 'blur' }
        ],
        auditor: [
          { required: true, message: '请输入复核人' },
          { pattern: RegexLib.EquipmentPeopleName, message: '只允许 1~10 位非特殊字符', trigger: 'blur' }
        ],
        payer: [
          { required: true, message: '请输入开票人' },
          { pattern: RegexLib.EquipmentPeopleName, message: '只允许 1~10 位非特殊字符', trigger: 'blur' }
        ],
        paperOrdinaryInvoiceLimit: [{ required: true, message: '请选择普票限额' }],
        paperSpecialInvoiceLimit: [{ required: true, message: '请选择专票限额' }],
        electronicOrdinaryInvoiceLimit: [{ required: true, message: '请选择电子普票限额' }],
      },
      rules2: {
        orgId: [{ required: true, message: '请选择所属组织' }],
        extensionNo: [
          { required: true, message: '请输入分机编号' },
          { pattern: RegexLib.EquipmentExtensionNo, message: '只允许 1~20 位数字或字母', trigger: 'blur' }
        ],
        agentPassword: [
          { required: true, message: '请输入助手密码' },
          { pattern: RegexLib.EquipmentAgentPassword, message: '只允许 8~20 位数字或字母', trigger: 'blur' }
        ],
      },
      billingChannels: [], // 开票方式
      invoiceLimits: ['10000.00', '100000.00', '1000000.00', '10000000.00'], // 发票限额
      provinces: [
        { value: '', label: '请选择' },
        { value: '1', label: '河北' },
        { value: '2', label: '山西' },
        { value: '3', label: '辽宁' },
        { value: '4', label: '吉林' },
        { value: '5', label: '黑龙江' },
        { value: '6', label: '江苏' },
        { value: '7', label: '浙江' },
        { value: '8', label: '安徽' },
        { value: '9', label: '福建' },
        { value: '10', label: '江西' },
        { value: '11', label: '山东' },
        { value: '12', label: '河南' },
        { value: '13', label: '湖北' },
        { value: '14', label: '湖南' },
        { value: '15', label: '广东' },
        { value: '16', label: '海南' },
        { value: '17', label: '四川' },
        { value: '18', label: '贵州' },
        { value: '19', label: '云南' },
        { value: '20', label: '陕西' },
        { value: '21', label: '甘肃' },
        { value: '22', label: '青海' },
        { value: '23', label: '台湾' },
        { value: '24', label: '北京' },
        { value: '25', label: '天津' },
        { value: '26', label: '上海' },
        { value: '27', label: '重庆' },
        { value: '28', label: '内蒙古' },
        { value: '29', label: '广西' },
        { value: '30', label: '西藏' },
        { value: '31', label: '宁夏' },
        { value: '32', label: '新疆' }],
      printerBrands:[],
      equipmentTypes:[],
      usbNames: [
        {value: 'SP-USB1', label: 'SP-USB1'},
        {value: 'SP-USB2', label: 'SP-USB2'},
        {value: 'SP-USB3', label: 'SP-USB3'},
        {value: 'SP-USB4', label: 'SP-USB4'},
        {value: 'SP-USB5', label: 'SP-USB5'}
      ],
      QDChannel: [
        "QD-XSD", "QD-RPA", "QD-LQ"
      ]
    }
  },
  created () {
    this.handleInitDict()
    this.getDicts('printer_brand').then(({ data }) => (this.printerBrands = data));
    this.getDicts('equipment_type').then(({ data }) => (this.equipmentTypes = data));
    if (this.QDChannel.indexOf(this.record.billingChannel) > -1) {
      this.isRpa = true
    }
  },
  methods: {
    changeNoYes () {
      if(this.record.equipmentType == 'PRINT'){
        this.record.billingChannel = '';
      }
      this.$refs.editForm.clearValidate()
    },
    // 初始化数据字典
    handleInitDict () {
      this.getDicts('equ_billing_mode').then(({ success, data }) => {
          if (success)
            this.billingChannels = [{ value: '', label: '全部' }, ...data]
        }
      )
    },
    // 判断开具类型
    specificMode () {
      return Array.from(arguments).findIndex(i => i === this.record.billingChannel) < 0
    },
    // 点击保存按钮
    handleSave () {
      this.$refs.editForm.validate((valid) => {
        if (!valid) return
        this.handleSubmitEquipment()
      })
    },
    // 提交-保存设备信息
    async handleSubmitEquipment () {
      this.loading = true
      if (this.QDChannel.indexOf(this.record.billingChannel) > -1) {
        this.record.elecType = 1
      }
      const { success, data } = await ReqEquipmentSaveEquipment(this.record)
      this.loading = false
      if (!success) return

      let mode = 'update'
      if (this.record.id == 0) {
        mode = 'create'
        this.record.id = data
      }
      this.$refs.editForm.resetFields()
      this.$emit('saved', JSON.parse(JSON.stringify(this.record)), mode)
    },
    // 点击取消
    handleCancel () {
      // this.$refs.editForm.resetFields()
      this.$emit('cancel')
    },
    // 补全助手名称
    async generateAgentUsername () {
      let orgId = this.record.orgId
      if (!orgId) {
        this.orgTaxpayer = {}
        this.record.agentUsername = ''
        return
      }
      const { success, data } = await selectOrganizationTaxpayer(this.record.orgId)
      if (success) {
        this.orgTaxpayer = data
        this.record.agentUsername = `${this.orgTaxpayer.taxNo}-${this.record.extensionNo}`
      } else {
        this.orgTaxpayer = {}
        this.record.agentUsername = ''
      }
    },
    async handlerBillChannel(value){
      this.isRpa = value !== 'QD-RPA' && value !== 'QD-XSD' && value !== 'QD-LQ'
    }
  }
}
</script>

<style lang="scss" scoped>
  .edit-wrap {

    hr {
      color: #cccccc;
    }

    .el-form-item {
      width: 33.3%;
      text-align: center;
      margin-bottom: 24px;

      ::v-deep .el-form-item__label {
        width: 100%;
        margin-left: 20px;
        text-align: left;
      }
    }

    ::v-deep .el-form-item__error {
      margin-left: 20px;
    }
  }

  .org-item, .form-item {
    display: flex;
    width: 100%;
    flex-flow: wrap;
    flex-direction: row;
    justify-content: flex-start;
  }

  .form-foot {
    display: flex;
    justify-content: flex-end;
  }

  .icon {
    color: #FF0000;
    margin-left: 3px;
  }
</style>
